<style>
.dashboardAlert {

}

</style>
<script>
import { VclCode } from 'vue-content-loading';
import {mapGetters, mapState} from "vuex";
import {PayPalBillingAgreementStatus, ProductCategory, SubscriptionStatus} from "@/enums";
import {formatBytes} from "@/methods";

export default {
  components: {
    PlayerStats: () => import('@/components/lazy/dashboard/PlayerStats'),
    VclCode
  },
  props: {

  },
  computed: {
    ...mapGetters(['getDTLocale']),
    ...mapState({
      username: 'username'
    }),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/info', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.info = data.info;
          this.gryphon = data.gryphon;
          this.realm = data.realm;

          if(this.gryphon !== null) {
            let usage = Number(((this.gryphon.storage.aggregated_size / this.gryphon.storage.quota)*100)).toFixed(1);
            this.gryphonSeries = this.gryphon.storage.quota <= 0 ? [0] : [usage];
            this.gryphonOptions['colors'] = usage > 90 ? ['#f46a6a'] : ['#34c38f'];
            if(this.gryphon.storage.quota === 0) {
              this.gryphonOptions.plotOptions.radialBar.track.background = '#34c38f';
            }
          }
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    formatBytes(b) {
      return formatBytes(b);
    }
  },
  created() {
    this.getData()
  },
  data() {
    return {
      ready: false,
      error: false,
      info: {},
      realm: {},
      gryphon: {},
      gryphonSeries: {},
      gryphonOptions: {
        chart: {
          height: 150,
              type: "radialBar",
              sparkline: {
            enabled: true,
          },
        },
        colors: ["#556ee6"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#000000",
              strokeWidth: "100%",
              margin: 5,
            },
            hollow: {
              size: "60%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                    fontSize: "16px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -15,
          },
        },
        labels: [this.$t('dashboard.gryphon.storage')],
      },
      SubscriptionStatus: SubscriptionStatus
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="dashboardAlert" v-if="info.subscription && info.subscription.flags && info.subscription.flags.unpaid === true">
      <div class="row">
        <div class="col text-center">
          <div class="alert alert-danger">
            <h5 class="alert-heading">
              <i class="fad fa-exclamation-triangle" />{{$t('subscribe.unpaid.title')}}
            </h5>
            <hr>
            {{$t('subscribe.unpaid.message')}}
          </div>
        </div>
      </div>
    </div>

    <div class="dashboardAlert" v-if="!info.subscription && !realm.has_servers">
      <div class="row">
        <div class="col text-center">
          <div class="alert alert-info btn-new-pulse">
            <h5 class="alert-heading text-black font-size-18">
              {{$t('subscribe.dashboard.title')}}
            </h5>
            <hr>
            <span class="text-black font-size-16">
              {{$t('subscribe.dashboard.message')}}
            </span>

            <div class="mt-2">
              <router-link to="/subscribe">
                <button class="btn btn-primary btn-lg text-uppercase">
                  {{$t('subscribe.prompt')}}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="info.subscription">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-2 col-sm-6">
                  <div class="media">
                    <div class="media-body align-self-center">
                      <div class="text-muted">
                        <p class="mb-2">{{$t('dashboard.welcome_back')}}</p>
                        <h5 class="mb-1">{{ username }}</h5>
                        <b class="mb-0">{{ $t(`dashboard.title.${info.title}`) }}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 align-self-center">
                  <div class="text-lg-center mt-4 mt-lg-0">
                    <div class="row justify-content-center">
                      <div class="col-4" v-if="info.subscription">
                        <div>
                          <p class="text-muted text-truncate mb-2">{{$t('terms.subscription')}}</p>
                          <h5 class="mb-0" v-if="info.subscription">
                            {{$t(`omega.${info.subscription.code}.title`)}}
                            <template v-if="info.subscription.expires_at">
                              <small v-if="info.subscription.status === SubscriptionStatus.CANCELLED">
                                <span class="small badge badge-pill badge-danger">
                                  {{$t('subscribe.active.expires')}}
                                  {{ $d(Date.parse(info.subscription.expires_at, {year: 'numeric', month: 'numeric', day: 'numeric'}), 'date', getDTLocale()) }}
                                </span>
                              </small>
                              <small v-else>
                                <span class="small badge badge-pill badge-primary">
                                  {{$t('subscribe.active.renews')}}
                                  {{ $d(Date.parse(info.subscription.expires_at, {year: 'numeric', month: 'numeric', day: 'numeric'}), 'date', getDTLocale()) }}
                                </span>
                              </small>
                            </template>
                          </h5>
                          <h5 class="mb-0" v-else>
                            <router-link to="/subscribe">
                              <button class="btn btn-sm btn-rounded btn-primary">
                                {{$t('subscribe.prompt')}}
                              </button>
                            </router-link>
                          </h5>
                        </div>
                      </div>
                      <!--
                      <div class="col-4">
                        <div>
                          <p class="text-muted text-truncate mb-2">{{$t('dashboard.notification_credit')}}</p>
                          <h5 class="mb-0">0 remaining</h5>
                        </div>
                      </div>
                      <div class="col-4">
                        <div>
                          <p class="text-muted text-truncate mb-2">{{$t('dashboard.storage')}}</p>
                          <h5 class="mb-0">1 MiB / 100 MiB</h5>
                        </div>
                      </div>
                      -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12" v-if="gryphon && info.subscription">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12 justify-content-center text-center">
                      <h5 class="p-0">
                        <i class="fas fa-database" />
                        {{ $t('dashboard.gryphon.title') }}
                      </h5>
                      <div class="text-muted mt-2">
                        <b>
                          {{ formatBytes(gryphon.storage.aggregated_size || 0) }}
                          /
                          <template v-if="gryphon.storage.quota <= 0">
                            Ꝏ
                          </template>
                          <template v-else>
                            {{ formatBytes(gryphon.storage.quota) }}
                          </template>
                          {{ $t('dashboard.gryphon.used') }}
                        </b>
                      </div>
                      <i>
                        {{ $t('dashboard.gryphon.question') }}
                        <router-link to="/support">
                          {{ $t('dashboard.gryphon.prompt') }}
                        </router-link>
                      </i>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <apexchart
                          class="apex-charts"
                          type="radialBar"
                          height="150"
                          dir="ltr"
                          :series="gryphonSeries"
                          :options="gryphonOptions"
                      ></apexchart>

                    </div>
                  </div>
                </div>
                <!--
                <div class="col-lg-4 d-none d-lg-block">
                  <div class="clearfix mt-4 mt-lg-0">
                    <b-dropdown class="float-right" right variant="primary">
                      <template v-slot:button-content>
                        Request limit increase
                      </template>
                      <b-dropdown-item>Action</b-dropdown-item>
                      <b-dropdown-item>Another action</b-dropdown-item>
                      <b-dropdown-item>Something else</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                -->
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <hr>
    </template>


    <PlayerStats></PlayerStats>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
